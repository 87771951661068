export const baseUrl = process.env.REACT_APP_API_PATH;

export const API_URLS = {
  getProfile: '/mticketing/v2/users',
  postProfile: '/mticketing/v2/user',
  putProfile: '/mticketing/v2/users',
  getOTP: '/chaukidar/v1/otp',
  validateOTP: '/chaukidar/v1/login',
  refreshToken: '/chaukidar/v1/refreshTokens',
  cityConfig: '/scheduler_v4/v4/chalo-cities-config',
  getRouteSearch: 'scheduler_v4/v4',
  productConfig: '/configuration/v3/cities',
  setProfileImage: '/files/v1/upload',
  busPassBooking: '/mticketing/v2/user/generatePass',
  checkOrderStatus: '/paymentmanager/v1/order/status',
  getPaymentStatus: '/paymentmanager/v1/payment/process',
  getPaymentMethods: '/paymentmanager/v1/payments/methods',
  getUserHistory: '/mticketing/v2/cities',
  getRouteLiveInfo: 'vasudha/track/route-live-info',
  getHomeCardInfo: '/nearbybus/v2/city',
  getCityMetaData: '/scheduler_v4/v7/metadataprops/',
  superPassBookingWithoutVerification: '/mticketing/v2/city',
  createPassOrder: '/mticketing/v3/pass/order',
  submitDocForVerificationSuperPass: '/mticketing/v2/city',
  reclaim: '/mticketing/v2/users',
  chaloTime: '/configuration/chalo-props/v1',
  getSeatAvailability: 'seat/availability',
  busTicketBooking: '/mticketing/v2/ticket/order',
  getTicketFare: '/mticketing/v1/cities',
  getRideHistory: 'mticketing/v2/punch/syncHistory',
  getTicketVerified: 'mticketing/v2/getPunch',
  getNearestBusStops: 'summary',
  getNearestBusStopsMap: 'nearby',
  getStopAllBuses: 'stopdetails',
  getRouteStopsETA: 'stop-route-eta',
  getTripPlannerCardsData: 'tripplanner',
  getPlaceData: 'scheduler_v4/v4/place/details',
  getFindMyBusCardsData: 'tripplanner/stop',
  userLogin: 'chaukidar/v1/app/login',
  getCardEligibility: 'mticketing/v1/card/eligibility',
  makeCardRecharge: 'mticketing/v2/card/recharge',
  getCardRechargeHistory: 'card/transactions',
  getCardRechargeConfig: 'configuration/v3/cities',
  getCardList: 'payments/public/wallet/v2/user/card',
  createNCMCOrder: 'mticketing/v2/wallet/ncmc/rechargeOrder',
  createNCMCOfflineOrder: 'mticketing/v1/wallet/ncmc/offlineRecharge',
  offlineNCMCPaymentStatus: 'mticketing/v1/wallet/ncmc/offlineRecharge/confirm',
  getInstantTicketFare: '/mticketing/v2/mobile-ticket/fare',
  linkCard: '/mticketing/v1/card/link-card',
  getCardDetails: '/mticketing/v1/card/card-info',
  getCardHistory: '/mticketing/v1/card/transaction-history',
  getAvailablePremiumPasses: '/mticketing/v2/pass/purchase-options',
  getBookingTripDetails: 'tub/v1/bookingTripDetails',
  getActionForPremiumTicket: '/mticketing/v1/reserve-ticket/getActions',
  getReasonsOptionsList: '/configuration/v1/getReasons',
  cancelPrebookedPremiumTrip: '/mticketing/v1/reserve-ticket/cancel-booking',
  bookPremiumTicketUsingPass: '/mticketing/v2/pass-ticket/order',
  reschedulePrebookedPremiumTrip: '/mticketing/v1/reserve-ticket/reschedule-booking',
  getCityStops: '/configuration/citydata/v2/cities',
  getODPairPremiumBuses: 'scheduler_v4/v4/mumbai/stopPairsBetweenPoints',
  getSlotDataForPremiumBuses: 'tub/v1/trips',
  // getCardRechargeHistory: 'mticketing/v1/cities',
  getPayForTicket: 'mticketing/v2/mobile-ticket/order',
  getInstantTicket: '/mticketing/v2/mobile-ticket/bookings',
  viewReceipt: '/mticketing/v2/mobile-ticket/receipt',
  validateFair: '/mticketing/v2/mobile-ticket/validate-fare',
  productConfigPremiumBuses: '/configuration/v1/cities',
  productFetchPlan: '/configuration/v1/product/fetch-plans',
  getVehicleRouteInfo: '/vasudha/location',
  getUniversalRouteSearch: 'scheduler_v4/v5',
  getStopBasedTrip: 'scheduler_v4/v5/',
};
